import { defineComponent, inject, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import axios from 'axios';
import { useMessageBox, withLoading, Selector, PromptPopup, usePromptPopup } from '@hems/component';
import { AuthService, JoinService, UserInfoService } from '@hems/service';
import { AuthHelper, Helper, DateHelper, LocalStorageHelper } from '@hems/util';
import { PROVIDE_INJECT_KEYS, localStorageKey } from '@hems/util/src/constant';
import { UnauthorizedException, AccessTokenParseException, SocialLoginException, UnknownErrorException, AxiosErrorException, } from '@hems/util/src/exception/exception';
import { LoginPopup } from '@/components';
import { getCountryList } from '@/config/countryConfig';
const MAX_RETRY_LOGIN_CNT = 3;
const RETRY_LOGIN_LOG_FLAG = ['local', 'development', 'staging'].includes(Helper.getAppEnv());
export default defineComponent({
    name: 'LoginPopupContainer',
    components: {
        LoginPopup,
        PromptPopup,
        Selector,
    },
    emits: ['afterLogin', 'close', 'join', 'findIdPw', 'reagree'],
    setup(_, { emit }) {
        const axiosInstance = axios.create({ baseURL: process.env.VUE_APP_API_URL });
        const loginService = new AuthService(axiosInstance);
        const validService = new JoinService(axiosInstance);
        const userInfoService = new UserInfoService(axiosInstance);
        const facebookOAuth = inject(PROVIDE_INJECT_KEYS.FACEBOOK_OAUTH);
        const googleOAuth = inject(PROVIDE_INJECT_KEYS.GOOGLE_OAUTH);
        const appleOAuth = inject(PROVIDE_INJECT_KEYS.APPLE_OAUTH);
        const store = useStore();
        const { t } = useI18n();
        const { envLocale, termsVer } = store.state.appCtx;
        const messageBox = useMessageBox();
        let loginRetryCount = 0;
        const passingRoles = (process.env.VUE_APP_ROLES_THAT_DONT_REQUIRE_AGREEMENT_TO_TERMS || '').split(',');
        let termVersion = `${termsVer}-${envLocale}`;
        const LocalStorageService = LocalStorageHelper.default;
        const countryState = reactive({
            countryList: computed(() => getCountryList().map((item) => ({ text: t(item.text), value: item.value }))),
            countrySelectionPopup: usePromptPopup(),
            countryCode: envLocale === 'EU' ? 'DE' : envLocale === 'US' ? 'US' : 'AU',
        });
        function isSuccess(resData) {
            return resData.access_token ?? false;
        }
        function onLogin(loginParam) {
            const { username, password } = loginParam;
            if (Helper.isNull(username) || Helper.isNull(password)) {
                messageBox.alert(t('message.invalid_id_pw')).open();
                return;
            }
            withLoading(async () => {
                try {
                    const isLoginFailedExpired = checkLoginFailedExpired(username);
                    if (isLoginFailedExpired !== null && !isLoginFailedExpired) {
                        await messageBox.alert([t('message.login_failed_5times'), t('message.try_again_1minute')]).open();
                        return;
                    }
                    const resData = await loginService.getAccessToken(loginParam);
                    removeLoginFailedItem(username);
                    if (!isSuccess(resData)) {
                        throw new UnauthorizedException();
                    }
                    const jwtObject = AuthHelper.parseJwt(resData.access_token);
                    if (!jwtObject) {
                        throw new AccessTokenParseException(resData.access_token);
                    }
                    // 토큰 값에 auth_type_cd가 없으면, 로그인 재시도
                    if (!jwtObject.auth_type_cd) {
                        if (loginRetryCount >= MAX_RETRY_LOGIN_CNT) {
                            loginRetryCount = 0;
                            messageBox.alert('message.retry_later').open();
                            return;
                        }
                        loginRetryCount += 1;
                        if (RETRY_LOGIN_LOG_FLAG) {
                            const { username, password } = loginParam;
                            addRetryLoginLogMessage('QOMMAND', username, password, resData.access_token);
                        }
                        onLogin(loginParam);
                        return;
                    }
                    // 1. 약관 동의가 불필요한 role의 경우, 바로 로그인 처리
                    const roleNm = AuthHelper.getRoleNm(jwtObject.auth_type_cd);
                    if (passingRoles.includes(roleNm)) {
                        emit('afterLogin', resData.access_token);
                        closePopup();
                        return;
                    }
                    // 계정의 국가 정보 확인
                    const accountCountryInfo = await userInfoService.getAccountCountryInfo(username, resData.access_token);
                    if (!accountCountryInfo || Helper.isNull(accountCountryInfo.cntry_cd)) {
                        // 계정 국가 정보가 없다면 국가 선택 UI 팝업 띄움
                        const isOk = await countryState.countrySelectionPopup.open();
                        if (isOk) {
                            // 국가 정보 업데이트
                            await userInfoService.updateAccountCountryInfo({ userId: username, cntryCd: countryState.countryCode }, resData.access_token);
                        }
                    }
                    else {
                        countryState.countryCode = accountCountryInfo?.cntry_cd ?? '';
                    }
                    // 국가가 뉴질랜드인 경우 nz-termsVer처리
                    if (envLocale === 'AU' && countryState.countryCode === 'NZ') {
                        termVersion = `${process.env.VUE_APP_TERMS_VER_NZ}-NZ`;
                    }
                    // 2. 약관 재동의 여부 확인
                    const checkTermVersion = await loginService.checkTermsVersion({
                        ver: termVersion,
                    }, resData.access_token);
                    if (!checkTermVersion.is_confirmed) {
                        emit('reagree', resData.access_token, termVersion);
                    }
                    else {
                        emit('afterLogin', resData.access_token);
                    }
                    closePopup();
                }
                catch (e) {
                    // 로그인 실패 만료 시간이 지났을 경우 실패 정보 삭제
                    const isLoginFailedExpired = checkLoginFailedExpired(username);
                    if (isLoginFailedExpired)
                        removeLoginFailedItem(username);
                    if (e instanceof UnauthorizedException) {
                        await messageBox.alert(t('message.incorrect_idpw_tryagain')).open();
                        // 로그인 실패 횟수 체크
                        updateLoginFailed(username);
                        return;
                    }
                    if (e instanceof AccessTokenParseException) {
                        await messageBox.alert([t('message.unknown_error'), '[error: token parser]']).open();
                        return;
                    }
                    if (e instanceof AxiosErrorException) {
                        const isRun = e.afterError({
                            400: async () => {
                                await messageBox.alert(t('message.incorrect_idpw_tryagain')).open();
                                // 로그인 실패 횟수 체크
                                updateLoginFailed(username);
                            },
                            401: async () => {
                                await messageBox.alert(t('message.incorrect_idpw_tryagain')).open();
                                // 로그인 실패 횟수 체크
                                updateLoginFailed(username);
                            },
                            500: async () => {
                                await messageBox.alert(t('message.login_server_error')).open();
                            },
                            503: async () => {
                                await messageBox.alert(t('message.login_server_error')).open();
                            },
                        });
                        if (isRun)
                            return;
                    }
                    await messageBox.alert(t('message.unknown_error')).open();
                }
            })();
        }
        function onSocialLogin(issuer, issuerToken, email, name) {
            if (Helper.isNull(email)) {
                messageBox.alert(t('message.email_registration_required')).open();
                return;
            }
            if (email.length > 50) {
                messageBox.alert(t('message.too_long_email')).open();
                return;
            }
            withLoading(async () => {
                try {
                    // 1. 가입여부 체크
                    try {
                        const data = await validService.checkUserIdEmail(email);
                        if (typeof data.result.is_valid !== 'boolean') {
                            messageBox.alert(t('message.retry_later')).open();
                            return;
                        }
                        const isExist = !data.result.is_valid;
                        if (!isExist) {
                            // 미가입 이메일일 경우,
                            throw new SocialLoginException();
                        }
                        else {
                            // 가입된 이메일이지만 일반 가입자인 경우
                            if (!['GOOGLE', 'FACEBOOK', 'APPLE'].includes(data.result.issuer)) {
                                messageBox.alert(t('message.exist_member', { email })).open();
                                return;
                            }
                            // 가입된 이메일이지만 issuer가 다를 경우
                            if (data.result.issuer !== issuer) {
                                messageBox
                                    .alert(t('message.exist_social_account', { email, issuer: Helper.capitalize(data.result.issuer) }))
                                    .open();
                                return;
                            }
                        }
                    }
                    catch (e) {
                        if (e instanceof SocialLoginException)
                            throw e;
                        throw new UnknownErrorException();
                    }
                    // 2. 토큰 생성
                    let resData;
                    try {
                        resData = await loginService.getAccessTokenBySocialToken(issuer, issuerToken, email);
                        if (!isSuccess(resData)) {
                            throw new UnknownErrorException();
                        }
                    }
                    catch (e) {
                        if (e instanceof AxiosErrorException) {
                            const isRun = e.afterError({
                                500: async () => {
                                    await messageBox.alert(t('message.login_server_error')).open();
                                },
                                503: async () => {
                                    await messageBox.alert(t('message.login_server_error')).open();
                                },
                            });
                            if (isRun)
                                return;
                        }
                        messageBox.alert(t('message.retry_later')).open();
                        return;
                    }
                    const jwtObject = AuthHelper.parseJwt(resData.access_token);
                    if (!jwtObject) {
                        throw new AccessTokenParseException(resData.access_token);
                    }
                    // 3. 토큰 값에 auth_type_cd가 없으면, 로그인 재시도
                    if (!jwtObject.auth_type_cd) {
                        if (loginRetryCount >= MAX_RETRY_LOGIN_CNT) {
                            loginRetryCount = 0;
                            messageBox.alert(t('message.retry_later')).open();
                            return;
                        }
                        loginRetryCount += 1;
                        if (RETRY_LOGIN_LOG_FLAG) {
                            addRetryLoginLogMessage(issuer, email, '', resData.access_token);
                        }
                        onSocialLogin(issuer, issuerToken, email, name);
                        return;
                    }
                    // 4. 약관 재동의
                    // 4-1. 약관 재동의가 불필요한 role의 경우, 바로 로그인 처리
                    const roleNm = AuthHelper.getRoleNm(jwtObject.auth_type_cd);
                    if (passingRoles.includes(roleNm)) {
                        emit('afterLogin', resData.access_token);
                        closePopup();
                        return;
                    }
                    // 계정의 국가 정보 확인
                    const accountCountryInfo = await userInfoService.getAccountCountryInfo(email, resData.access_token);
                    if (!accountCountryInfo || Helper.isNull(accountCountryInfo.cntry_cd)) {
                        // 계정 국가 정보가 없다면 국가 선택 UI 팝업 띄움
                        const isOk = await countryState.countrySelectionPopup.open();
                        if (isOk) {
                            // 국가 정보 업데이트
                            await userInfoService.updateAccountCountryInfo({ userId: email, cntryCd: countryState.countryCode }, resData.access_token);
                        }
                    }
                    else {
                        countryState.countryCode = accountCountryInfo?.cntry_cd ?? '';
                    }
                    // 국가가 뉴질랜드인 경우 nz-termsVer처리
                    if (countryState.countryCode === 'NZ') {
                        termVersion = `${process.env.VUE_APP_TERMS_VER_NZ}-NZ`;
                    }
                    // 4-2. 약관 재동의 여부 확인
                    const checkTermVersion = await loginService.checkTermsVersion({
                        ver: termVersion,
                    }, resData.access_token);
                    if (!checkTermVersion.is_confirmed) {
                        emit('reagree', resData.access_token, termVersion);
                    }
                    else {
                        emit('afterLogin', resData.access_token);
                    }
                    closePopup();
                }
                catch (e) {
                    if (issuer === 'FACEBOOK' && (await facebookOAuth.isLogin()))
                        facebookOAuth.logout();
                    else if (issuer === 'GOOGLE' && (await googleOAuth.isLogin()))
                        googleOAuth.logout();
                    if (e instanceof SocialLoginException) {
                        // 소셜 로그인 실패 시 계정 없는 것으로 간주, 소셜계정으로 회원 가입
                        if (await messageBox.confirm([t('message.unreg_account', { email }), t('message.join')]).open()) {
                            emit('join', { issuer, issuerToken, email, name });
                        }
                        return;
                    }
                    if (e instanceof AccessTokenParseException) {
                        await messageBox.alert([t('message.unknown_error'), '[error: token parser]']).open();
                        return;
                    }
                    await messageBox.alert(t('message.unknown_error')).open();
                }
            })();
        }
        function closePopup() {
            emit('close');
        }
        async function googleLogin() {
            let accessToken;
            let email;
            let name;
            try {
                // 구글 로그인 instance 여부 체크 - 쿠키 차단 확인
                // if (!googleOAuth.isInit()) {
                //   await messageBox
                //     .alert([
                //       t('message.social_access_fail', { issuer: Helper.capitalize('google') }),
                //       t('message.thirdparty_cookie_block'),
                //       t('message.chrome_cookie'),
                //     ])
                //     .open();
                //   return;
                // }
                // await googleOAuth.login();
                accessToken = googleOAuth.getAccessToken();
                email = googleOAuth.getEmail();
                name = googleOAuth.getName();
                onSocialLogin('GOOGLE', accessToken, email, name);
            }
            catch (e) {
                console.error(e);
            }
        }
        async function facebookLogin() {
            let accessToken;
            let email;
            let name;
            try {
                await facebookOAuth.login();
                accessToken = await facebookOAuth.getAccessToken();
                email = facebookOAuth.getEmail();
                name = facebookOAuth.getName();
                onSocialLogin('FACEBOOK', accessToken, email, name);
            }
            catch (e) {
                console.error(e);
            }
        }
        async function appleLogin() {
            let accessToken;
            let email;
            let name;
            try {
                await appleOAuth.login();
                accessToken = appleOAuth.getAccessToken();
                email = appleOAuth.getEmail();
                name = appleOAuth.getName();
                onSocialLogin('APPLE', accessToken, email, name);
            }
            catch (e) {
                console.error(e);
            }
        }
        async function addRetryLoginLogMessage(issure, id, pwd, token) {
            const logMessage = {
                token,
                id,
                pwd: issure === 'QOMMAND' ? pwd : `login by ${issure}`,
                time: DateHelper.formatToday('YYYY/MM/DD HH:mm:ss'),
            };
            await loginService.addRetryLoginLog(logMessage);
        }
        function checkLoginFailedExpired(id) {
            const loginFailedItem = LocalStorageService.getOneItem(localStorageKey.loginFailed, id);
            if (loginFailedItem) {
                if (loginFailedItem.expireTime) {
                    const isExpired = new Date().getTime() > new Date(loginFailedItem.expireTime).getTime();
                    return isExpired;
                }
                return null;
            }
            return null;
        }
        function updateLoginFailed(id) {
            // 전체 로그인 실패 정보
            const loginFailedItems = LocalStorageService.get(localStorageKey.loginFailed);
            // 로그인 시도한 계정에 대한 로그인 실패 정보
            const loginFailedItem = LocalStorageService.getOneItem(localStorageKey.loginFailed, id);
            if (loginFailedItems) {
                if (loginFailedItem) {
                    const updatedLoginFailedItem = {
                        ...loginFailedItem,
                        count: loginFailedItem.count + 1,
                    };
                    if (loginFailedItem.count < 4) {
                        LocalStorageService.updateOneItem(localStorageKey.loginFailed, id, updatedLoginFailedItem);
                    }
                    else {
                        const date = new Date();
                        updatedLoginFailedItem.expireTime = new Date(date.getTime() + 60000).toString();
                        LocalStorageService.updateOneItem(localStorageKey.loginFailed, id, updatedLoginFailedItem);
                    }
                }
                else {
                    insertNewLoginFailedItem(id);
                }
            }
            else {
                // 어떤 계정에 대해서도 로그인 실패 정보가 없는 경우
                insertNewLoginFailedItem(id);
            }
        }
        function insertNewLoginFailedItem(id) {
            const newLoginFailedItem = {
                id,
                count: 1,
            };
            LocalStorageService.insertOneItem(localStorageKey.loginFailed, newLoginFailedItem);
        }
        function removeLoginFailedItem(id) {
            LocalStorageService.removeOneItem(localStorageKey.loginFailed, id);
        }
        return {
            countryState,
            onLogin,
            closePopup,
            googleLogin,
            facebookLogin,
            appleLogin,
        };
    },
});
