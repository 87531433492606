/** Copyright © 2024 Qcells. All rights reserved.
 *
 * This software is proprietary and confidential. Unauthorized use,
 * duplication, or distribution of software is strictly prohibited.
 */
import { Helper } from '@hems/util';
const euCountryList = [
    {
        value: 'DE',
        text: 'code.cntrycd.de',
    },
    {
        value: 'FR',
        text: 'code.cntrycd.fr',
    },
    {
        value: 'GB',
        text: 'code.cntrycd.gb',
    },
    {
        value: 'IE',
        text: 'code.cntrycd.ie',
    },
    {
        value: 'PT',
        text: 'code.cntrycd.pt',
    },
];
const auCountryList = [
    {
        value: 'AU',
        text: 'code.cntrycd.au',
    },
    {
        value: 'NZ',
        text: 'code.cntrycd.nz',
    },
];
const usCountryList = [
    {
        value: 'US',
        text: 'code.cntrycd.us',
    },
];
const krCountryList = [
    {
        value: 'KR',
        text: 'code.cntrycd.kr',
    },
];
export function getCountryList() {
    const region = Helper.getEnvLocale();
    switch (region) {
        case 'EU':
            return euCountryList;
        case 'AU':
            return auCountryList;
        case 'US':
            return usCountryList;
        default:
            return euCountryList.concat(auCountryList).concat(usCountryList).concat(krCountryList);
    }
}
